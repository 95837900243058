<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#707487;"
      d="M386.251,134.696c-1.36,0-2.738-0.307-4.027-0.96c-4.43-2.228-6.22-7.628-3.992-12.062
	c25.914-51.481,62.416-101.168,72.294-111.042C457.342,3.817,466.387,0.039,476.01,0c0.044,0,0.097,0,0.149,0
	c9.588,0,18.58,3.72,25.335,10.479c3.509,3.509,3.509,9.197,0,12.703c-3.509,3.509-9.194,3.509-12.703,0
	c-3.387-3.387-8.097-5.242-12.711-5.216c-4.843,0.022-9.404,1.93-12.851,5.373c-7.22,7.216-42.809,54.482-68.942,106.414
	C392.708,132.889,389.541,134.696,386.251,134.696z"
    />
    <path
      style="fill:#FFDC64;"
      d="M413.209,53.898H289.892c-14.294,0-28.003,5.678-38.111,15.786L5.283,316.181
	c-7.017,7.016-7.017,18.391,0,25.407l165.149,165.149c7.017,7.017,18.391,7.017,25.407,0l246.497-246.498
	c10.108-10.107,15.786-23.816,15.786-38.111V98.812C458.124,74.007,438.014,53.898,413.209,53.898z M405.316,144.816
	c-10.525,10.525-27.588,10.525-38.111,0c-10.525-10.525-10.525-27.587,0-38.111s27.588-10.525,38.111,0
	S415.84,134.293,405.316,144.816z"
    />
    <path
      style="fill:#FFC850;"
      d="M32.246,343.144L278.742,96.646c10.108-10.107,23.817-15.786,38.111-15.786H440.17
	c5.343,0,10.394,1.094,15.147,2.805c-6.234-17.315-22.646-29.767-42.109-29.767H289.892c-14.294,0-28.003,5.678-38.111,15.786
	L5.283,316.181c-7.017,7.016-7.017,18.391,0,25.407l26.962,26.962C25.229,361.536,25.229,350.16,32.246,343.144z"
    />
    <path
      style="fill:#7F8499;"
      d="M386.269,134.696c-3.29,0-6.456-1.807-8.035-4.947c-2.228-4.43-0.439-9.83,3.992-12.062
	c51.924-26.12,99.19-61.718,106.41-68.939c3.447-3.447,5.352-8.009,5.377-12.851c0.018-4.816-1.834-9.33-5.219-12.715
	s-8.097-5.242-12.711-5.216c-4.843,0.022-9.404,1.93-12.851,5.373c-3.509,3.509-9.194,3.509-12.703,0
	c-3.509-3.504-3.509-9.194,0-12.703C457.343,3.816,466.387,0.039,476.011,0c0.044,0,0.097,0,0.149,0
	c9.588,0,18.58,3.72,25.335,10.479c6.799,6.794,10.518,15.847,10.483,25.488c-0.044,9.618-3.816,18.668-10.641,25.484
	c-9.869,9.878-59.565,46.389-111.042,72.289C389.006,134.389,387.62,134.696,386.269,134.696z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M242.533,369.643c-6.202,0-11.229-5.027-11.229-11.229V180.563c0-6.202,5.027-11.229,11.229-11.229
		s11.229,5.027,11.229,11.229v177.852C253.762,364.617,248.735,369.643,242.533,369.643z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M179.012,307.666c-10.193,0-19.782-3.974-26.993-11.185c-7.211-7.207-11.185-16.795-11.185-26.993
		c0-10.198,3.974-19.786,11.185-26.997c7.211-7.207,16.799-11.18,26.993-11.18c10.202,0,19.791,3.974,26.993,11.189
		c7.211,7.207,11.185,16.795,11.185,26.988c0,10.198-3.974,19.786-11.185,26.997C198.803,303.692,189.215,307.666,179.012,307.666z
		 M179.012,253.768c-4.193,0-8.141,1.636-11.114,4.606c-2.973,2.97-4.606,6.917-4.606,11.114c0,4.197,1.632,8.145,4.606,11.111
		c5.948,5.952,16.282,5.925,22.229,0.009c2.973-2.973,4.606-6.921,4.606-11.119c0-4.197-1.632-8.145-4.606-11.111
		c0-0.004,0-0.004,0-0.009C187.153,255.404,183.214,253.768,179.012,253.768z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M306.054,307.666c-10.202,0-19.791-3.974-26.993-11.189c-7.211-7.202-11.185-16.79-11.185-26.988
		c0-10.198,3.974-19.786,11.185-26.997c7.202-7.207,16.79-11.18,26.993-11.18c10.193,0,19.782,3.974,26.993,11.185
		c7.211,7.211,11.185,16.795,11.185,26.993c0,10.198-3.974,19.786-11.185,26.997C325.835,303.692,316.247,307.666,306.054,307.666z
		 M306.054,253.768c-4.202,0-8.141,1.636-11.114,4.602c-2.973,2.973-4.606,6.921-4.606,11.119c0,4.197,1.632,8.145,4.606,11.111
		c5.948,5.943,16.282,5.957,22.229,0.004c2.973-2.97,4.606-6.917,4.606-11.114c0-4.197-1.632-8.145-4.606-11.111
		c0-0.004,0-0.004,0-0.004C314.195,255.404,310.248,253.768,306.054,253.768z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
